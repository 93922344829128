<template>
    <About :content="pageContent"/>
</template>

<script>
import About from '@/components/About.vue'

export default {
    data(){
    return {
      pageContent: null
    }
  },  
    components:{
        About
    },
    methods:{
        async getCurrentPageContent(){
            let _this = this
            const content = await _this.getPage('3')
            _this.pageContent = content[0]      
        },
    },     
    created(){
        this.getCurrentPageContent()   
    }
}
</script>