<template>
    <div :id="`aboutSlider_${slider.id}`" class="carousel slide carousel-fade position-relative about__slider" data-ride="carousel">
        <div class="carousel-inner">
            <div 
                :class="{'active': currentSlide == s2ind}"
                v-for="(slide, s2ind) in slider.earthlings_slider_item" :key="slide.id"
                class="carousel-item"
            >
                <div class="row">
                    <!-- slide text -->
                    <div class="col-12 col-lg-6 text-white">
                        <div v-html="slide.description"></div>
                    </div>
                    <!-- arrows xs,sm,md -->
                    <div class="col-12 col-lg-1 about-arrows d-flex flex-lg-column justify-content-end justify-content-lg-start align-items-lg-end">
                        <!--<span class="carousel-control-prev-icon" :data-target="`#aboutSlider_${slider.id}`" data-slide="prev"></span>
                        <span class="carousel-control-next-icon" :data-target="`#aboutSlider_${slider.id}`" data-slide="next"></span>-->
                    </div>
                    <!-- slide img -->
                    <div class="col-12 col-lg-5 text-center pt-3 pt-lg-0 px-2">                        
                        <img :src="sliderImg(slide)" class="img-fluid carousel-img w-100" alt="...">
                    </div>
                </div>
            </div>
        </div>
        <!-- arrows lg -->
        <!-- <div class="col-12 col-lg-7 about-arrows flex-lg-column flex-row justify-content-around d-none d-lg-flex">
            <span class="carousel-control-prev-icon" :data-target="`#aboutSlider_${slider.id}`" data-slide="prev"></span>
            <span class="carousel-control-next-icon" :data-target="`#aboutSlider_${slider.id}`" data-slide="next"></span>
        </div> -->
    </div>
</template>

<script>

export default {
  props:['slider'],
  data() {
    return {      
      currentSlide: 0
    }
  },  
  methods:{
    sliderImg (slide){
        return slide.image ? this.apiUrl + '/' + slide.image.path +  slide.image.file_name : ""
    }
  }
}
</script>

<style scoped>

</style>