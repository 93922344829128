<template>
    <div>
        <div class="container-fluid about" v-if="content">
            <div class="row" v-for="slider in content.earthlings_page_block[0].earthlings_slider" :key="slider.id">
                <div class="col-12 px-2">
                    <AboutSlider :slider="slider" />                
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AboutSlider from "@/components/AboutSlider"

export default {
    props:['content'],
    components:{
        AboutSlider
    }
}
</script>
